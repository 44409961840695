import React from "react"
import { graphql } from "gatsby"
import { Base64 } from "js-base64"

import ToolLayout from "../../../components/tool_layout"
import PipeWidget from "../../../components/widgets/pipe"

export const query = graphql`
  query Base64EncodeQuery {
    allToolsJson(filter: { path: { eq: "base64/encode" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class Base64EncodePage extends React.Component<{ data: any }> {
  render() {
    const transformer = Base64.encode

    return (
      <ToolLayout data={this.props.data}>
        <PipeWidget
          transformer={transformer}
          inputProps={{
            placeHolderText: "Input text here",
          }}
        ></PipeWidget>
      </ToolLayout>
    )
  }
}

export default Base64EncodePage
